.LandingPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.LandingPage-Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Images/Background/background-4.jpg");
}

.LandingPage-Background:after {
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  position: absolute;
}


.LandingPage-Title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 9vh;
  text-align: center;
  color: #9cb3da;
  text-shadow: -2px -2px 2px #728192, 2px -2px 2px #728192, -2px 2px 2px #728192, 2px 2px 2px #728192;
  opacity: 0.75;
  cursor: default;
  font-size: 72px;
  font-weight: bold;
  font-style: italic;
  font-family: Superclarendon, 'Bookman Old Style', 'URW Bookman', 'URW Bookman L', 'Georgia Pro', Georgia, serif;
}

.LandingPage-Title:hover {
  opacity: 0.85;
  text-shadow: -3px -3px 4px #728192, 3px -3px 4px #728192, -3px 3px 4px #728192, 3px 3px 4px #728192;
}


.LandingPage-Social-Icons-Container {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
}


.LandingPage-Social-Icon {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  margin: 0 10px;
  opacity: 0.3;
}

.LandingPage-Social-Icon:hover {
  opacity: 1;
}

.LandingPage-Social-Icon img {
  width: 100%;
  height: 100%;
}


.LandingPage-Copyright-Container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  padding: 0;
  text-align: left;
  cursor: default;
  opacity: 0.75;
  color: #eeeeff;
  font-size: 14px;
  font-weight: 100;
  font-style: italic;
  font-family: Superclarendon, 'Bookman Old Style', 'URW Bookman', 'URW Bookman L', 'Georgia Pro', Georgia, serif;
}

.LandingPage-Copyright-Container:hover {
  opacity: 1;
}


/* If the screen size is 900px wide or less */
@media screen and (max-width: 900px) {
  .LandingPage-Title {
    font-size: 54px;
  }
}

/* If the screen size is 800px wide or less or 150px tall or less */
@media screen and ((max-width: 800px) or (max-height: 150px)) {
  .LandingPage-Social-Icons-Container {
    bottom: 40px;
  }
  .LandingPage-Copyright-Container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    margin: 0;
    font-size: 13px;
    font-weight: 200;
    text-align: center;
  }
}

/* If the screen size is 600px wide or less */
@media screen and (max-width: 600px) {
  .LandingPage-Title {
    font-size: 38px;
    left: 10%;
    width: 80%;
  }
}

/* If the screen size is 150px tall or less */
@media screen and (max-height: 150px) {
  .LandingPage-Title {
    display: none;
  }
  .LandingPage-Social-Icons-Container {
    bottom: 40px;
  }
}

