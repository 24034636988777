body, html {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #000115;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
